const actions = {
  gameDefault: [
    {
      title: 'Создать турнир',
      route: '/create',
      icon: 'plus.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Редактировать турнир',
      route: '/crud',
      icon: 'plus.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Создать мега турнир',
      route: '/create-mega',
      icon: 'plus.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Отменить',
      route: '/cancel',
      icon: 'close.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Отменить все турниры',
      route: '/cancel-all-tournaments',
      icon: 'close.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Отвязка аккаунта',
      route: '/unlink-account',
      icon: 'group.svg',
      roles: ['admin', 'ceo', 'support'],
    },

    {
      title: 'Привязка аккаунта',
      route: '/link-account',
      icon: 'group.svg',
      roles: ['admin', 'ceo', 'support'],
    },

    {
      title: 'Поиск по никнейму игровых аккаунтов',
      route: '/check-account',
      icon: 'group.svg',
      roles: ['admin', 'ceo', 'support'],
    },

    {
      title: 'Изменить рейтинг',
      route: '/rating',
      icon: 'up-down.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Изменить участников',
      route: '/members',
      icon: 'up-down.svg',
      roles: ['admin', 'ceo'],
    },
  ],
  'valorant': [
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-pubg-mobile', 'senior-moderator-pubg-mobile', 'ceo', 'moderator'],
    },
  ],
  lichess: [
    {
      title: 'Отменить',
      route: '/cancel',
      icon: 'close.svg',
      roles: ['senior-moderator-lichess'],
    },
    {
      title: 'Отвязка аккаунта',
      route: '/unlink-account',
      icon: 'group.svg',
      roles: ['moderator-lichess', 'senior-moderator-lichess'],
    },
    {
      title: 'Проверить на мульти-аккаунт',
      route: '/multi-account',
      icon: 'group.svg',
      roles: ['admin', 'moderator-lichess', 'senior-moderator-lichess', 'ceo'],
    },

    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-lichess', 'senior-moderator-lichess', 'ceo', 'moderator'],
    },
    {
      title: 'Изменить рейтинг',
      route: '/rating',
      icon: 'up-down.svg',
      roles: ['senior-moderator-lichess'],
    },
    {
      title: 'Заявки на привязку',
      route: '/account-verification',
      icon: 'check-list.svg',
      roles: ['admin', 'moderator-lichess', 'senior-moderator-lichess', 'ceo'],
    },
  ],

  'pubg-mobile': [
    {
      title: 'Отменить',
      route: '/cancel',
      icon: 'close.svg',
      roles: ['senior-moderator-pubg-mobile'],
    },
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: [
        'admin',
        'moderator-pubg-mobile',
        'senior-moderator-pubg-mobile',
        'ceo',
        'moderator'
      ],
    },
    {
      title: 'Отвязка аккаунта',
      route: '/unlink-account',
      icon: 'group.svg',
      roles: ['senior-moderator-pubg-mobile'],
    },
    {
      title: 'Заявки на привязку',
      route: '/account-verification',
      icon: 'check-list.svg',
      roles: [
        'admin',
        'moderator-pubg-mobile',
        'senior-moderator-pubg-mobile',
        'ceo',
      ],
    },
    {
      title: 'Изменить рейтинг',
      route: '/rating',
      icon: 'up-down.svg',
      roles: ['senior-moderator-pubg-mobile'],
    },
  ],

  'pubg-new-state': [
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: [
        'admin',
        'moderator-pubg-new-state',
        'senior-moderator-pubg-new-state',
        'ceo',
        'moderator'
      ],
    },
    {
      title: 'Заявки на привязку',
      route: '/account-verification',
      icon: 'check-list.svg',
      roles: [
        'admin',
        'moderator-pubg-new-state',
        'senior-moderator-pubg-new-state',
        'ceo',
      ],
    },
  ],

  'brawl-stars': [
    {
      title: 'Отменить',
      route: '/cancel',
      icon: 'close.svg',
      roles: ['senior-moderator-brawl-stars'],
    },
    {
      title: 'Отвязка аккаунта',
      route: '/unlink-account',
      icon: 'group.svg',
      roles: ['senior-moderator-brawl-stars'],
    },

    {
      title: 'Привязка аккаунта',
      route: '/link-account',
      icon: 'group.svg',
      roles: ['senior-moderator-brawl-stars'],
    },
    {
      title: 'Изменить рейтинг',
      route: '/rating',
      icon: 'up-down.svg',
      roles: ['senior-moderator-brawl-stars'],
    },
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-brawl-stars', 'senior-moderator-brawl-stars', 'ceo', 'moderator', 'support'],
    },
  ],

  'call-of-duty-pc': [
    {
      title: 'Отменить',
      route: '/cancel',
      icon: 'close.svg',
      roles: ['admin', 'moderator-call-of-duty-pc', 'ceo'],
    },
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-call-of-duty-pc', 'ceo', 'moderator'],
    }
  ],

  'call-of-duty-mobile': [
    {
      title: 'Отменить',
      route: '/cancel',
      icon: 'close.svg',
      roles: ['admin', 'moderator-call-of-duty-mob', 'ceo'],
    },
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-call-of-duty-mob', 'ceo', 'moderator'],
    },
  ],

  'among-us': [
    {
      title: 'Отменить',
      route: '/cancel',
      icon: 'close.svg',
      roles: ['admin', 'moderator-among-us', 'ceo'],
    },
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-among-us', 'ceo', 'moderator'],
    },
  ],

  'clash-royale': [
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-clash-royale', 'ceo', 'senior-moderator-clash-royale', 'moderator'],
    },
    {
      title: 'Отменить',
      route: '/cancel',
      icon: 'close.svg',
      roles: ['senior-moderator-clash-royale'],
    },
    {
      title: 'Изменить рейтинг',
      route: '/rating',
      icon: 'up-down.svg',
      roles: ['senior-moderator-clash-royale'],
    },
    {
      title: 'Отвязка аккаунта',
      route: '/unlink-account',
      icon: 'group.svg',
      roles: ['senior-moderator-clash-royale'],
    },
  ],

  'pubg-desktop': [
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-pubg-pc', 'senior-moderator-pubg-pc', 'ceo', 'moderator'],
    },
  ],
  'dota2': [
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-dota2', 'ceo', 'moderator'],
    },
  ],
  'mobile-legends': [
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-mobile-legends', 'ceo', 'moderator'],
    },
  ],
  'fortnite': [
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-fortnite', 'ceo', 'moderator'],
    },
  ],
  'apex-legends': [
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-apex-legends', 'senior-moderator-pubg-pc', 'ceo', 'moderator'],
    },
  ],

  'free-fire': [
    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: [
        'admin',
        'moderator-free-fire',
        'senior-moderator-free-fire',
        'ceo',
        'moderator'
      ],
    },

    {
      title: 'Отменить',
      route: '/cancel',
      icon: 'close.svg',
      roles: ['senior-moderator-free-fire'],
    },

    {
      title: 'Заявки на привязку',
      route: '/account-verification',
      icon: 'check-list.svg',
      roles: [
        'admin',
        'moderator-free-fire',
        'senior-moderator-free-fire',
        'ceo',
      ],
    },
  ],

  standoff: [
    {
      title: 'Отменить',
      route: '/cancel',
      icon: 'close.svg',
      roles: ['moderator-standoff', 'senior-moderator-standoff'],
    },

    {
      title: 'Events',
      route: '/events',
      icon: 'edit.svg',
      roles: ['admin', 'moderator-standoff', 'senior-moderator-standoff', 'ceo', 'moderator'],
    },

    {
      title: 'Заявки на привязку',
      route: '/account-verification',
      icon: 'check-list.svg',
      roles: [
        'admin',
        'moderator-standoff',
        'senior-moderator-standoff',
        'ceo',
      ],
    },
  ],

  other: [
    {
      title: 'Забанить / разбанить',
      route: '/ban',
      icon: 'user.svg',
      roles: [
        'admin',
        'moderator-lichess',
        'ceo',
        'senior-moderator-pubg-mobile',
        'senior-moderator-free-fire'
      ],
    },

    {
      title: 'Евро',
      route: '/money',
      icon: 'flatten.svg',
      roles: ['ceo'],
    },

    {
      title: 'G-cash',
      route: '/g-cash',
      icon: 'flatten.svg',
      roles: ['ceo'],
    },

    {
      title: 'Монеты',
      route: '/coins',
      icon: 'flatten.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Заказы',
      route: '/marketplace',
      icon: 'check-list.svg',
      roles: ['admin', 'ceo', 'support'],
    },

    {
      title: 'Продавцы',
      route: '/sellers',
      icon: 'check-list.svg',
      roles: ['admin', 'ceo', 'support'],
    },

    {
      title: 'Выкуп билетов',
      route: '/tickets-exchange',
      icon: 'flatten.svg',
      roles: ['ceo'],
    },

    {
      title: 'Отправить уведомление',
      route: '/notify',
      icon: 'notifications.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Отправить массовую рассылку в тг',
      route: '/notify-telegram',
      icon: 'notifications.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Верификация',
      route: '/verification',
      icon: 'user.svg',
      roles: ['ceo'],
    },

    {
      title: 'Управление автовыводом',
      route: '/payout',
      icon: 'notifications.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Массовая блокировка вывода/ввода',
      route: '/payout-status',
      icon: 'notifications.svg',
      roles: ['ceo'],
    },
    {
      title: 'Подтверждение почты',
      route: '/email-verify',
      icon: 'notifications.svg',
      roles: ['admin', 'ceo', 'support'],
    },

    {
      title: 'Поиск айди по почте',
      route: '/check-account-by-email',
      icon: 'group.svg',
      roles: ['admin', 'ceo', 'support'],
    },

    {
      title: 'FAQ',
      route: '/faq',
      icon: 'group.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Редактировать турнир',
      route: '/edit-tournament',
      icon: 'edit.svg',
      roles: [
        'admin',
        'ceo',
        'senior-moderator-pubg-mobile',
        'senior-moderator-lichess',
      ],
    },

    {
      title: 'Возобновление мониторинга',
      route: '/reset-monitoring',
      icon: 'plus.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Автогенерация турниров',
      route: '/tournament-parser',
      icon: 'plus.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Автогенерация турниров на сегодня',
      route: '/daily-tournament-parser',
      icon: 'plus.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Автогенерация турниров на неделю',
      route: '/weekly-tournament-parser',
      icon: 'plus.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Автогенерация турниров сразу',
      route: '/daily-instant-tournament-parser',
      icon: 'plus.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Автогенерация мега-турниров',
      route: '/mega-tournament-parser',
      icon: 'plus.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Синхронизация переводов',
      route: '/sync-translations',
      icon: 'up-down.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Lawyers reports',
      route: '/lawyers-report',
      icon: 'group.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Обновить баннеры',
      route: '/banners',
      icon: 'edit.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Бонус для турнира',
      route: '/sale',
      icon: 'sale.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Управление рефералкой',
      route: '/referral',
      icon: 'sale.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Списание по ref id',
      route: '/referral-debiting',
      icon: 'sale.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Заявки на добавление игр и приложений',
      route: '/app-offers',
      icon: 'user.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Центр заданий',
      route: '/quests',
      icon: 'sale.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Инфлюенсеры',
      route: '/influencers',
      icon: 'user.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Покупки игровой валюты',
      route: '/game-currencies',
      icon: 'user.svg',
      roles: ['admin', 'ceo'],
    },

    {
      title: 'Выполнение оффера',
      route: '/complete-offer',
      icon: 'user.svg',
      roles: ['admin', 'ceo'],
    },
  ],
};

export default actions;
