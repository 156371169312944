<template lang="pug">
.chat-app
  .chat-app__header
    .chat-app__header-title
      | {{ chatTitle }}
    .chat-app__header-icons
      .chat-app__header-close(@click="close")
        InlineSvg(:src="require('@/assets/img/icons/close.svg')")

  .chat-app__messages(:key="`chat_${chatId}`")
    .chat-app__messages-body(ref="messagesBody")
      div(v-for="(message, i) in messages" :key="message.id")
        .chat-app__message(:class="{'chat-app__message--my': isMyMessage(message)}" :id="messages.length - 1 === i ? 'last-message' : undefined")
          .chat-app__message-user(:class="{'chat-app__message-user--my': isMyMessage(message.text), 'chat-app__message-user--system': message.account_id === 0}")
            | {{ message.user_name }}
            .chat-app__message-user-label(:class="{'chat-app__message-user-label--system': message.account_id === 0}")
              | {{ labelText(message) }}
          .chat-app__message-text
            span(v-html="messageText(message)")
          .chat-app__message-created {{ created(message) }}
  .chat-app__send-group
    .chat-app__send
      FormInput.card-form__field-input(
        v-model="text"
        placeholder="Текст сообщения"
        icon="send"
        icon-right
        @iconClick="sendMessage"
        @keyup.enter.native="sendMessage"
      )
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import BaseSelect from '@/components/BaseSelect/BaseSelect'
import FormInput from '@/components/InputForm/InputForm'
import ChatService from '@/services/ChatService'

export default {
  name: 'ChatApp',

  components: {
    BaseSelect,
    FormInput
  },

  data () {
    return {
      chatWith: undefined,
      messages: [],
      text: '',
      uploadedFile: undefined,
      disableButtons: false,
      chats: [],
      userNames: undefined,
      product: undefined,
      interval: undefined
    }
  },

  computed: {
    ...mapState('chat', ['chatId', 'chatName']),

    chatTitle () {
      return this.chatName
    },

    orderId () {
      return this.chatId?.split('_')?.slice(-1)?.[0]
    },

    order () {
      return [...(this.orders || []), ...(this.sales || [])]?.find(o => +o.id === +this.orderId)
    },

    productId () {
      return this.order?.content?.items?.[0]?.id
    },

    productAmount () {
      return this.order?.content?.items?.[0]?.amount || 1
    }
  },

  watch: {
    chatId () {
      this.messages = []
      this.loadChatMessages()
      clearInterval(this.interval)
      this.interval = setInterval(this.loadChatMessages, 15000)
    },

    uploadedFile () {
      if (this.uploadedFile) {
        this.sendMessage('file', this.uploadedFile)
      }
    }
  },

  async mounted () {
    this.loadChatMessages()
    this.interval = setInterval(this.loadChatMessages, 15000)

    setTimeout(this.scrollToBottom, 1000)
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  methods: {
    ...mapMutations('chat', ['setChatId', 'setChatName', 'setChatProductId']),
    ...mapActions('modal', ['showModal']),

    messageOrderId (message) {
      return message?.text?.replace('}', '')?.split('id:')?.[1]?.split(';;')?.[0]
    },

    messageProductId (message) {
      return message?.text?.replace(']]]', ';;')?.split('[[[product:')?.[1]?.split(';;')?.[0]
    },

    messageProductName (message) {
      if (this.messageProductId(message)) {
        return message?.text?.replace(']]]', ';;')?.split('[[[product:')?.[1]?.split(';;')?.[1]
      }
      return undefined
    },

    scrollToBottom () {
      this.$refs.messagesBody.scrollTop = this.$refs.messagesBody.scrollHeight
    },

    async loadChatMessages () {
      const newMessages = (await ChatService.getChatMessages(this.$axios, this.chatId, this.messages?.filter?.(m => m.created_at)?.slice?.(-1)?.[0]?.id))?.data || []
      if (this.messages) {
        this.messages = this.messages.filter(m => m.created_at || !newMessages.map(nm => nm.text).includes(m.text))
        this.messages.push(...newMessages)
      } else {
        this.messages = newMessages
      }

      setTimeout(this.scrollToBottom, 1000)
    },

    async sendMessage (type = 'text') {
      const recipients = this.chatId.split('_')

      try {
        if (this.text.trim() === '') {
          return
        }

        const message = {
          text: this.text,
          account_id: 0,
          chat_id: this.chatId,
          user_name: 'GG Support'
        }

        this.messages = [...this.messages, message]

        this.text = ''

        await ChatService.sendMessage(this.$axios, this.chatId, message)
        try {
          await ChatService.sendNotification(
            this.$axios,
            +recipients[0],
            0,
            null
          )
          await ChatService.sendNotification(
            this.$axios,
            +recipients[1],
            0,
            null
          )
        } catch (e) {
          console.log(e)
        }
        this.loadChatMessages()
      } catch (e) {
        console.log('CHAT ERROR:', e)
      }
    },

    isMyMessage (message) {
      return +(message.account_id) === 0
    },

    isFile (text) {
      return text.startsWith('[file:http')
    },

    isImgFile (text) {
      return this.isFile(text) && (
        text.endsWith('.png]') ||
        text.endsWith('.jpg]') ||
        text.endsWith('.jpeg]')
      )
    },

    fileUrl (text) {
      return text.replace(/\[file:(.*)\]/, '$1')
    },

    fileName (text) {
      // eslint-disable-next-line no-useless-escape
      return text.replace(/\[file:(.*)\/([^\.]*).(.*)\]/, '$2')
    },

    messageText (message) {
      return message.text
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
    },

    labelText (message) {
      if (message.account_id === 0) {
        return 'notification'
      }
    },

    created (message) {
      return message.created_at
      // if (!message.created_at) {
      //   return ''
      // }
      // return this.$dateFns.format(message.created_at, 'dd.MM.yyyy hh:mm')
    },

    close () {
      this.setChatId(null)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-app {
  position: fixed;
  --header-height: 1px;
  top: var(--header-height);
  right: 0;
  display: grid;
  grid-template-rows: max-content 1fr max-content max-content;
  width: 100%;
  border-left: 1px solid $blue-grey30;
  height: 100vh;
  max-height: 100%;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
  background: $blue-grey80;
  z-index: 1000;

  @media (min-width: $breakpointDesktopMedium) {
    max-width: 500px;
    --header-height: 1px;
    padding-top: 120px;
    z-index: 2;
  }

  &--with-product {
    grid-template-rows: max-content max-content 1fr max-content 1fr;
  }

  &__product {
    @include scroll;

    min-height: 30vh;
    max-height: 50vh;
    padding: 0 16px 16px;
    overflow-y: auto;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid $blue-grey40;
    border-top: 1px solid $blue-grey40;

    &-title {
      width: 100%;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: $white;

      &-status {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: $white;

        &__sellerTextNotOnline {
          color: $blue-grey10;
        }

        &__sellerTextOnline {
          color: $green60;
        }

        &__sellerTextHour {
          color: $yellow60;
        }

        &__sellerTextToday {
          color: $orange60;
        }

        &__sellerTextYesterday {
          color: $pink60;
        }
      }
    }

    &-back {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin-right: 16px;
    }

    &-icons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      background: $blue-grey60;
      padding: 6px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 16px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    &-dots {
      width: 24px;
      height: 24px;
      cursor: pointer;

      ::v-deep path {
        fill: $white;
      }
    }
  }

  &__order {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 0;
    border-bottom: 1px solid $blue-grey40;
    height: max-content;

    &-number {
      display: inline-block;
      margin-right: 12px;
      padding: 4px 8px;
      background: $primary60;
      color: $white;
      font-weight: 700;
      font-size: 12px;
      line-height: 100%;
      border-radius: 8px;
    }

    &-img {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 12px;
    }

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $blue-grey10;
      text-decoration: underline;
    }

    &-amount {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: $amber60;
      margin-left: 8px;
    }
  }

  &__messages {
    @include scroll;
    width: 100%;
    padding: 16px;
    overflow: hidden;

    &-body {
      display: grid;
      grid-gap: 8px;
      overflow-y: auto;
      max-height: 100%;

      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $blue-grey80;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $blue-grey60;
        border-radius: 4px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $blue-grey05;
      }
    }
  }

  &__chats {
    @include scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    overflow-y: auto;
    padding-bottom: 40px;

    &-chat {
      display: grid;
      grid-template-columns: 48px 1fr 23px;
      grid-gap: 12px;
      align-items: center;
      width: 100%;
      padding: 12px;
      margin-bottom: 8px;
      border-radius: 8px;
      background: $blue-grey60;
      color: $white;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      height: max-content;
      cursor: pointer;

      &--support {
        background: $primary60-10;
        border: 1px solid $primary60;
      }

      &-cover {
        width: 48px;
        height: 48px;
        background: $blue-grey70;
        border-radius: 50%;
        padding: 2px;

        &--support {
          background: $primary60;
          padding: 12px;
        }

        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;

          &--support {
            ::v-deep path {
              fill: $white;
            }
          }
        }
      }

      &-title {
        color: $white;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }

      &-counter {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 23px;
        width: 23px;
        background: $primary60;
        color: $white;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }

  &__message {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 6px;
    align-items: flex-start;
    background: $blue-grey60;
    padding: 12px 16px;
    width: 85%;
    margin-left: 9px;
    border-radius: 12px 12px 12px 0;

    &--my {
      border-radius: 12px 12px 0 12px;
      margin-right: 9px;
      margin-left: calc(15% - 9px);
      background: rgba($cyan60, .2);
    }

    &-user {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $orange60;
      overflow: hidden;
      text-overflow: ellipsis;

      &--my {
        color: $cyan60;
      }

      &--system {
        color: $primary60;
      }

      &-label {
        padding: 0 4px;
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        border-radius: 2px;

        &--system {
          background: $primary60;
          color: $white;
        }
      }
    }

    &-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $white;
    }

    &-info {
      display: block;
      margin: 12px 0 0;
      padding: 8px 16px;
      background: $primary60-20;
      color: $white;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      border-radius: 8px;

      &-item {
        color: $primary60;
        font-weight: 700;
      }
    }

    &-img {
      width: 80%;
      max-height: 200px;

      img {
        width: 100%;
        height: 200px;
        object-fit: contain;
      }
    }

    &-created {
      color: rgba($white, .5);
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      text-align: right;
    }

    &-buttons {
      margin: 12px 0 6px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &-button {
      margin: 2px;
    }
  }

  &__send {
    background: $blue-grey90;

    &-group {
      display: grid;
      grid-template-columns: 1fr 42px;
      grid-gap: 8px;
      align-items: center;
      background: $blue-grey90;
      padding: 12px 16px;
    }
  }

  &__upload-icon {
    margin: auto;
    cursor: pointer;
  }
}
</style>
