<template lang="pug">
  BaseLayout
    router-view
    ChatApp(v-if="chatIsOpen" @close="switchChat" :key="chatId")
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import layout from '@/layouts/BaseLayout'
import BaseLayout from '@/layouts/BaseLayout'
import ChatApp from '@/components/ChatApp/ChatApp'

export default {
  name: 'App',

  components: {
    BaseLayout,
    layout,
    ChatApp
  },

  computed: {
    ...mapState('user', ['token']),
    ...mapState('chat', ['chatId']),
    ...mapGetters('user', ['isJustUser']),

    chatIsOpen () {
      return !!this.chatId
    }
  },

  mounted() {
    this.$router.onReady(() => this.checkForUser())
  },

  created() {
    this.init()
  },

  methods: {
    ...mapActions('games', ['fetchGames']),
    ...mapActions('user', ['fetchUser']),

    init() {
      console.log('%c%s', 'color: white; background: #141e2b; border-radius: 4px;', '  Made with ♥️  ')
      console.log('ENV:', process.env.NODE_ENV)
    },

    switchChat () {
      this.setChatId(null)
      this.setChatName(null)
    },

    async checkForUser() {
      this.$loading.start()

      if (this.$route.query.token) {
        localStorage.setItem('token', this.$route.query.token)

        await this.$router.replace({ query: null })
      }

      const localToken = localStorage.getItem('token')

      if (localToken) {
        await this.fetchGames()
        await this.fetchUser()

        if (!this.token || this.isJustUser) {
          window.location.href = process.env.VUE_APP_DOMAIN
        }
      } else {
        window.location.href = process.env.VUE_APP_DOMAIN
      }

      this.$loading.finish()
    }
  },
}
</script>
