import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import games from '@/store/games'
import user from '@/store/user'
import modal from '@/store/modal'
import moderation from '@/store/moderation'
import navigation from '@/store/navigation'
import nominations from '@/store/nominations'
import chat from '@/store/chat'

const store = new Vuex.Store({
  namespaced: true,

  state: {
    isLoading: false,
  },

  getters: {
    isLoading: (state) => state.isLoading
  },

  mutations: {
    setLoadingStatus: (state, status) => state.isLoading = status,
  },

  actions: {},

  modules: {
    games,
    user,
    modal,
    moderation,
    navigation,
    nominations,
    chat
  }
})

export default store