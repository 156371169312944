import ChatService from '@/services/ChatService'

const state = () => ({
  chatId: null,
  chatName: '',
  newMessages: null,
  productData: null,
  chatProductId: null
})

const mutations = {
  setChatId (state, chatId) {
    state.chatId = chatId

    if (!chatId) {
      state.chatName = ''
    }
  },

  setChatName (state, chatName) {
    state.chatName = chatName
  },

  setNewMessages (state, messages) {
    state.newMessages = messages
  },

  setChatProductData (state, data) {
    state.productData = data
  },

  setChatProductId (state, data) {
    state.chatProductId = data
  }
}

const actions = {
  async fetchNewMessages ({ commit }, userId) {
    try {
      const data = (await ChatService.getNotReceivedMessages(this.$axios, userId))?.data || []
      commit('setNewMessages', data)
    } catch (e) {
      console.error(`${new Date().toUTCString()} :: fetch new messages error ::`, e)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
