import { render, staticRenderFns } from "./InputForm.vue?vue&type=template&id=913085f4&lang=pug"
import script from "./InputForm.vue?vue&type=script&lang=js"
export * from "./InputForm.vue?vue&type=script&lang=js"
import style0 from "./InputForm.vue?vue&type=style&index=0&id=913085f4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports